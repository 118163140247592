<script>


export default {

    emits: ['confirm', 'cancel'],

    props: {
        landlord : {
            type : Object,
            default: ()=> {

            }
        }
    },
    components: {
        
    },

    validations: {
       
    },
 

    data() {
      
        return {
            submitted : false,
        }
       
    },
    methods: {
       
    },

    created() {
       
    },

    mounted() {
       
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
          <div class="row">
            <b-alert show variant="danger" >
                <b-spinner  variant="danger" label="Spinning" class="me-2" ></b-spinner>
                Transaction is processing, please waiting.....
            </b-alert>
          </div>
            
        </div>
    </div>
    <!-- end card -->
</template>